<template>
  <div>
    <header-announce v-if="hasMounted"></header-announce>
    <div>
      <header :class="`es-header navbar ${isAnniversary ? 'anniversary-navbar' : ''} ${showHeaderShadow ? 'shadow' : ''}`" id="pc-header">
        <div class="navbar-header">
          <div class="visible-xs navbar-mobile">
            <a class="navbar-more" @click="toggleMobileMenu"> <i class="es-icon es-icon-menu"></i> </a>
          </div>
          <router-link class="navbar-brand" :to="{ path: '/' }"> <img :src="logoUrl" v-default-img-alt="'LOGO'" /> </router-link>
        </div>
        <nav class="collapse navbar-collapse">
          <ul v-if="routeAboutus" class="nav navbar-nav clearfix hidden-xs">
            <template v-for="(item, index) in aboutMenu">
              <li class="nav-hover" :key="item.id" v-if="index < 6">
                <router-link @click="clickMenu($event, item)" :to="item.url" :target="item.isNewWin ? '_blank' : ''">
                  {{ item.name }}
                </router-link>
              </li>
            </template>
          </ul>
          <ul v-else class="nav navbar-nav clearfix hidden-xs">
            <template v-for="(item, index) in menu">
              <li class="nav-hover" :key="item.id" v-if="index < 6">
                <a v-if="item.name && item.name.includes('周年庆')" class="anniversary-tab" @click="clickMenu($event, item)" :href="item.url" :target="item.isNewWin ? '_blank' : ''">
                  <img :src="topIconUrl" />
                </a>
                <a v-else @click="clickMenu($event, item)" :href="item.url" :target="item.isNewWin ? '_blank' : ''">
                  {{ item.name }}
                  <el-icon v-if="item.children" class="el-icon" name="arrow-down"></el-icon>
                </a>
                <ul class="dropdown-menu" role="menu" v-if="item.children">
                  <li v-for="subItem in item.children" :key="subItem.id">
                    <a :href="subItem.url" :target="subItem.isNewWin ? '_blank' : ''">{{ subItem.name }}</a>
                  </li>
                </ul>
              </li>
            </template>
            <template v-for="(item, index) in menu">
              <li :key="item.id" class="nav-more nav-hover" v-if="index === 6">
                <a class="more"> <i class="es-icon es-icon-morehoriz"></i> </a>
                <ul class="dropdown-menu" role="menu">
                  <li>
                    <router-link :to="item.url" :target="item.isNewWin ? '_blank' : ''">{{ item.name }}</router-link>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
          <div class="head-right" v-if="hasMounted">
            <div class="navbar-user" v-if="!isLogin">
              <ul class="nav user-nav">
                <li @click="SET_LOGIN_DIALOG_VISIBLE(true)"><a href="javascript:;">登录/注册</a></li>
              </ul>
              <transition name="el-fade-in">
                <div v-if="showLoginTips" class="login-tip">
                  <img src="@/assets/images/login/login-tip-animate.png" alt="" />
                </div>
              </transition>
            </div>
            <div class="navbar-user">
              <ul class="nav user-nav">
                <a class="search-entry" href="/search/index" target="_blank">
                  <svg class="icon search-icon" aria-hidden="true">
                    <use xlink:href="#icon-sousuoicon"></use>
                  </svg>
                </a>
                <li v-if="isLogin" class="nav-hover">
                  <a href="/notification/show" class="notification-icon-container">
                    <svg class="icon notification-icon" aria-hidden="true">
                      <use xlink:href="#icon-xiaoxitongzhi"></use>
                    </svg>
                    <span v-show="unreadNotifications && unreadNotifications.length" class="dot">{{ unreadNotifications.length }}</span>
                  </a>
                  <dropdown-notification :notifications="unreadNotifications" v-on:readAllNotifications="readAllNotifications"></dropdown-notification>
                </li>
                <li class="nav-hover hidden-xs" v-if="isLogin && isTeacher"><router-link :to="{ path: '/my-teaching' }">我的教学</router-link></li>
                <li class="nav-hover hidden-xs" v-else><a href="javascript:;" @click="goMyStudy">我的学习</a></li>
                <li v-if="isLogin" :class="'user-avatar-li nav-hover ' + (active ? 'open' : '')" @mouseenter="trigger" @mouseleave="trigger">
                  <a href="javascript:;" class="dropdown-toggle">
                    <img class="avatar-face" :src="me.smallAvatar" v-default-img:avatar v-default-img-alt="'头像'" />
                    <img v-if="myVip && myVip.levelInfo && myVip.levelInfo.laceIcon" class="avatar-vip" :src="myVip.levelInfo.laceIcon" v-default-img-alt="'头像'" />
                  </a>
                  <ul class="dropdown-menu" role="menu">
                    <li role="presentation" class="dropdown-header pr5">
                      <div class="item header-name">
                        <span class="nickname">{{ me.nickname }}</span>
                        <img v-if="myVip && myVip.levelInfo && myVip.levelInfo.leftAngleIcon" class="picture-vip" :src="`${myVip.levelInfo.leftAngleIcon}.svg`" v-default-img-alt="'VIP'" />
                      </div>
                      <div class="item student-id">学号：{{ me.studentid }}</div>
                    </li>
                    <li role="menu" class="nav-item" v-for="(item, index) in myDropMenu" :key="index">
                      <a :href="item.path" class="hidden-lg" v-if="item.name === 'notification'">
                        <i :class="'iconfont ' + item.icon" :style="'color:' + (item.color ? item.color : '')"></i> {{ item.label }}
                      </a>
                      <a :href="`/new-user/${me.id}`" v-else-if="item.name === 'new-user'">
                        <i :class="'iconfont ' + item.icon" :style="'color:' + (item.color ? item.color : '')"></i> {{ item.label }}
                      </a>
                      <a href="javascript:" @click="runLogout(item.path)" v-else-if="item.name === 'logout'">
                        <i :class="'iconfont ' + item.icon" :style="'color:' + (item.color ? item.color : '')"></i> {{ item.label }}
                      </a>
                      <a href="javascript:" @click="goTurtleAdmin" v-else-if="item.name === 'admin'">
                        <i :class="'iconfont ' + item.icon" :style="'color:' + (item.color ? item.color : '')"></i> {{ item.label }}
                      </a>

                      <a :href="item.path" v-else> <i :class="'iconfont ' + item.icon" :style="'color:' + (item.color ? item.color : '')"></i> {{ item.label }} </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'PcHeader',
  data() {
    return {
      active: false,
      searchVal: '',
      hasMounted: false,
      routeAboutus: false,
      showLoginTips: false,
      hadInitWatch: false, // 是否触发过监听了
      aboutMenu: [
        { id: 1, name: '关于BT教育', parentId: 6, url: '/aboutus/about-bt', sequence: 11, isNewWin: 0 },
        { id: 2, name: '企业文化', parentId: 6, url: '/aboutus/company-culture', sequence: 12, isNewWin: 0 },
        { id: 3, name: '旗下品牌', parentId: 6, url: '/aboutus/bt-brand', sequence: 13, isNewWin: 0 },
        { id: 4, name: '媒体报道', parentId: 6, url: '/aboutus/media-report', sequence: 14, isNewWin: 0 },
        { id: 5, name: '联系我们', parentId: 6, url: '/aboutus/contact-us', sequence: 15, isNewWin: 0 },
        { id: 6, name: '加入我们', parentId: 6, url: '/aboutus/join-us', sequence: 17, isNewWin: 0 },
      ],
    };
  },
  props: {
    isPC: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderAnnounce: () => import('./Announcement'),
    DropdownNotification: () => import('./DropdownNotification'),
  },
  computed: {
    ...mapGetters(['menu']),
    ...mapGetters('my', ['me', 'myVip', 'myDropMenu', 'isLogin', 'unreadNotifications']),
    isTeacher: function () {
      return !!(this.me.roles && this.me.roles.includes('|ROLE_TEACHER|'));
    },
    logoUrl: function () {
      return (this.$root.isAnniversary && this.$root.logoUrl) ? this.$root.logoUrl : 'https://app-cdn.btclass.cn/logo/bt-logo-v1.svg';
    },
    topIconUrl: function () {
      return this.$root.topIconUrl;
    },
    isAnniversary: function () {
      // 是否周年庆活动
      return this.$root.isAnniversary;
    },
  },
  serverPrefetch() {
    this.initOnResize();
    return this.getMenu();
  },
  mounted() {
    this.hasMounted = true;
    this.getMenu();
    // if (!this.menu || this.menu.length === 0) {
    //   this.getMenu();
    // }
    this.initOnResize();
    if (this.$route.path.indexOf('aboutus') !== -1) {
      this.routeAboutus = true;
    }
  },
  watch: {
    $route(to) {
      this.routeAboutus = to.path.indexOf('aboutus') !== -1;
    },
    myDropMenu: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && !this.hadInitWatch) {
          this.hadInitWatch = true;
          this.initOnResize();
        }
      },
    },
  },
  created() {
    if (!this.isPC) {
      import('./header.debug.css');
    }
    if (!sessionStorage.getItem('hadShowLoginTips')) {
      this.showLoginTips = true;
      setTimeout(() => {
        this.showLoginTips = false;
        sessionStorage.setItem('hadShowLoginTips', 1);
      }, 6000);
    }
  },
  methods: {
    ...mapActions(['getMenu']),
    ...mapActions('my', ['readNotifications']),
    ...mapMutations('my', ['SET_LOGIN_DIALOG_VISIBLE', 'SET_MY_DROP_MENU']),
    initOnResize() {
      if (this.myDropMenu.length > 0) {
        this.changeMyMenu();
      }
    },
    goMyStudy() {
      if (this.isLogin) {
        this.$router.push({ name: 'learning-center' });
      } else {
        this.SET_LOGIN_DIALOG_VISIBLE('/learning-center');
      }
    },
    // 跳转至turtle管理后台
    goTurtleAdmin() {
      window.open(process.env.VUE_APP_TURTLE_URL);
    },
    changeMyMenu() {
      let myDropMenu = [].concat(this.myDropMenu);
      let myMsg = {
        icon: 'es-icon-v2-wodexiaoxi',
        label: '我的消息',
        name: 'my-notification',
        path: '/notification/show',
      };
      let index = myDropMenu.findIndex(v => {
        return v.name === 'my-notification';
      });
      if (index === -1) {
        myDropMenu.splice(myDropMenu.length - 1, 0, myMsg);
        this.SET_MY_DROP_MENU(myDropMenu);
      }
    },
    clickMenu(e, item) {
      if (!item || item.children) {
        e.preventDefault();
        return false;
      }
    },
    search() {
      this.$router.push({
        path: '/search',
        query: {
          q: this.searchVal,
        },
      });
    },
    trigger() {
      this.active = !this.active;
    },
    toggleMobileMenu() {
      this.$bus.$emit('toggleMobileMenu');
    },
    readAllNotifications() {
      this.readNotifications();
    },
    showHeaderShadow() {
      return this.$route.name && !this.$route.meta.showHeaderShadow;
    },
    runLogout(url) {
      this.$store.commit('my/LOGOUT');
      location.href = url;
    },
  },
};
</script>
<style scoped lang="scss">
@import './header.css';

#pc-header {
  z-index: 2000;
}

.dropdown-menu > li > a:hover {
  background: #4b8fff !important;
}

.shadow {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1) !important;
}

// .anniversary-navbar {
//   .navbar-brand {
//     display: flex;
//     align-items: center;
//     padding: 0 20px 0 40px !important;
//   }
// }

.navbar-user {
  position: relative;
  background-color: #fff;
  .login-tip {
    position: absolute;
    z-index: 30;
    top: 40px;
    left: 50%;
    margin-left: -85px;
    width: 170px;
    height: 52px;
    animation: step-reverse 0.8s infinite;
    img {
      width: 100%;
    }
  }

  @keyframes step-reverse {
    0% {
      top: 55px;
    }

    50% {
      top: 48px;
    }

    100% {
      top: 55px;
    }
  }
}
</style>
